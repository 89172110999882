/* src/NavBar/NavBar.css */
.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.header-top {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content */
  width: 100%;
  max-width: 1200px; /* Restrict the maximum width */
  padding: 10px;
  gap: 20px; /* Add gap between logo and title */
}

.header-title-text {
  font-size: 40px;
  font-family: 'RoboticBold';
  text-align: center;
}

.header-logo {
  height: 150px;
  width: 150px;
  background-image: url("/src/Assets/Images/logo500.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.header-navigation-items ul {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: center; /* Center the navigation items horizontally */
}

.header-navigation-items li {
  margin: 10px; /* Add some spacing between the items */
}

.header-navigation-items a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4900ff;
  font-family: Robotic;
  font-size: 20px;
  color: #000000;
  text-decoration: none;
  border-radius: 50px;
  transition: background-color 0.3s ease;
}

.header-navigation-items a:hover {
  background-color: #00b8ff;
}

/* Media Queries for Responsive Design */
@media (max-width: 900px) {
  .header-title-text {
      font-size: 30px; /* Smaller font size for medium screens */
  }

  .header-logo {
      height: 100px;
      width: 100px;
  }

  .header-navigation-items a {
      font-size: 18px; /* Adjust font size */
  }
}

@media (max-width: 600px) {
  .header-top {
      flex-direction: column; /* Stack logo and title vertically */
      align-items: center; /* Center items vertically */
  }

  .header-title-text {
      font-size: 24px; /* Smaller font size for small screens */
      margin-top: 10px;
  }

  .header-logo {
      height: 80px;
      width: 80px;
  }

  .header-navigation-items a {
      font-size: 16px; /* Adjust font size */
  }
}