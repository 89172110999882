/* src/GameScreen/GameScreen.css */
.game-screen-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically (if needed) */
    width: 100%; /* Take full width of the parent container */
    width: 1124px;
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px; /* Optional: Add padding around the container */
    box-sizing: border-box; /* Include padding in the width calculation */
    background-color: #4b0082;
    border-radius: 50px;
  }
  
  .game-title {
    display: inline-block; /* Allow width and padding to be applied */
    background-color: #4b0082; /* Dark purple background color */
    color: #ffffff; /* White text color */
    padding: 20px; /* Padding around the text */
    width: 100%; /* Take full width of the parent container */
    max-width: 1300px; /* Max width of 1600px */
    box-sizing: border-box; /* Ensure padding is included in width */
    margin: 0 auto; /* Center the container horizontally */
    font-family: 'RoboticBold';
    font-size: 32px;
  }
  
  .game-description {
    display: inline-block; /* Allow width and padding to be applied */
    background-color: #4b0082; /* Dark purple background color */
    color: #ffffff; /* White text color */
    padding: 20px; /* Padding around the text */
    width: 100%; /* Take full width of the parent container */
    max-width: 1600px; /* Max width of 1600px */
    box-sizing: border-box; /* Ensure padding is included in width */
    margin: 0 auto; /* Center the container horizontally */
    margin-top: 0;
  }


@media screen and (max-width: 1366px) {
    .game-screen-container{
        width: 1124px;
    }
}

@media screen and (max-width: 1280px) {
    .game-screen-container{
        width: 1000px;
    }
}

@media screen and (max-width: 1024px) {
    .game-screen-container{
        width: 954px;
    }
}

@media screen and (max-width: 960px) {
    .game-screen-container{
        width: 410px;
    }
}