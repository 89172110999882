.App {
  text-align: center;
  background-color: #9600ff;
  min-height: 100vh;
  background-image: url('./Assets/Images/background.png'); /* Adjust the path to your image */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'RoboticBold';
  src: url('./Assets/Fonts/destructobeam-bb/destructobeambb_bold.ttf');
}

@font-face {
  font-family: 'Robotic';
  src: url(./Assets/Fonts/destructobeam-bb/destructobeambb_reg.ttf);
}
