.game-container {
    width: 1024px;
    height: 576px;
}

@media screen and (max-width: 1366px) {
    .game-container{
        width: 1024px;
        height: 576px;
    }
}

@media screen and (max-width: 1280px) {
    .game-container{
        width: 960px;
        height: 540px;
    }
}

@media screen and (max-width: 1024px) {
    .game-container{
        width: 854px;
        height: 480px;
    }
}

@media screen and (max-width: 960px) {
    .game-container{
        width: 400px;
        height: 225px;
    }
}

@media screen and (max-width: 740) {
    .game-screen-container{
      width: 240px;
      height: 135px;
    }
}

.fullscreen-button {
    display: block;
    margin: 10px auto;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }