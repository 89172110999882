/* src/HomeScreen/HomeScreen.css */
.home-screen-container {
  padding: 20px;
  display: flex;
  justify-content: center; /* Center the grid container */
}

.thumbnail-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  gap: 10px; /* Spacing between thumbnails */
  width: 100%; /* Full width of the parent container */
  max-width: 1200px; /* Set a max-width for the grid to prevent it from stretching too wide */
}

@media (max-width: 1200px) {
  .thumbnail-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 columns on smaller screens */
  }
}

@media (max-width: 900px) {
  .thumbnail-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on even smaller screens */
  }
}

@media (max-width: 600px) {
  .thumbnail-grid {
      grid-template-columns: 1fr; /* 1 column on very small screens */
  }
  .home-screen-container {
      justify-content: center; /* Ensure centering on very small screens */
  }
}