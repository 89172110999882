.thumbnail-container {
  width: 100%;
  max-width: 250px; /* Ensure a maximum width for larger screens */
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(75, 0, 130, 1);
  margin: 10px auto; /* Add margin for spacing */
  padding-top: 50px; /* Add padding to accommodate the title */
  position: relative; /* Make sure the container is positioned relatively */
}

.title-container {
  color: #fff;
  padding: 5px;
  width: 100%;
  text-align: center;
  background-color: rgba(75, 0, 130, 1); /* Ensure the background is visible */
  position: absolute; /* Pin to the top */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  z-index: 1; /* Ensure it is above other content */
}

.image-container {
  width: 100%;
  height: 150px; /* Fixed height for the image container */
  overflow: hidden; /* Hide overflow to maintain image size */
}

.image-container img {
  width: 100%;
  height: 100%; /* Ensure the image fills the container */
  object-fit: cover; /* Cover the area of the container */
}

.description-container {
  padding: 5px;
  text-align: center;
  font-size: 14px;
  color: #ddd;
  margin-bottom: 1px; /* Add margin to ensure space for the button */
}

.button-container {
  width: 100%;
  padding: 10px;
  text-align: center;
  position: relative; /* Use relative positioning */
  margin-top: auto; /* Push the button to the bottom of the container */
}

.button-container a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  padding: 10px 20px;
  background-color: #5e35b1; /* Lighter purple */
  border-radius: 4px;
  transition: background-color 0.3s;
}

.button-container a:hover {
  background-color: #7e57c2; /* Even lighter purple on hover */
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .thumbnail-container {
      max-width: 200px; /* Adjust max-width for smaller screens */
  }
  .image-container {
      height: 130px; /* Adjust height for smaller screens */
  }
}

@media (max-width: 900px) {
  .thumbnail-container {
      max-width: 180px; /* Adjust max-width for smaller screens */
  }
  .image-container {
      height: 120px; /* Adjust height for smaller screens */
  }
}

@media (max-width: 600px) {
  .thumbnail-container {
      max-width: 160px; /* Adjust max-width for smaller screens */
  }
  .image-container {
      height: 110px; /* Adjust height for smaller screens */
  }
}
